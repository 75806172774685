.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}
.table th, .table td {
    padding: 2px;
    padding-left: 15px;
}

.table {
    margin-bottom: 0px;
}
