@import "../../scss/variables";
.layout-login-container {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
  .body{
      display: table-cell;
      vertical-align: middle;
      user-select: none;
      .content {
        margin: 0 auto;
        width: 350px;
        position: relative;
        background-color: white;
        padding: 35px;
        padding-bottom: 15px;
        border-radius: 5px;
        text-align: center;
        
        .logo{
          height: 40px;       
        }
        .form{
          text-align: left;
          a{
            color: $linkColor;
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
          .title{
            font-size: 15px;
            margin-top: 7px;
            float: right;
            margin-bottom: 20px;
            color: #404040;
            padding-left: 2px;
            display: inline-block;
          }
          .forgetPassBtn{
            float: right;
          }
          .signUpBtn{
            padding-left: 3px;
          }
          .custom-control-label{
            font-weight: normal;
          }
          .tipPhone{
            font-size: 9px;
          }
          .privacy{
            display: block;
            width: 100%;
            .privacyForm{
              width: 210px;
              float: left;
            }
            .privacyLink{
              width: 55px;
              cursor: pointer;
              color: $linkColor;
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
}

@media (max-width: 400px) {
  .layout-login-container .body .content
  {
    width: 300px;
  }
}
