.main-modal-container {
    .modal-header {
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
    }
    .modal-body {
        padding: 10px 25px;
        .action-row {
            padding: 10px 0px;
            float: right;
            margin: 0px;
            .action-btn {
                margin: 0px 5px;
            }
        }
    }
}

.react-responsive-modal-root{
    z-index: 100000000 !important;
}