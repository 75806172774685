@import "../../../scss/variables";
.custom-auto-suggest-container {
	.input-container {
		width: 100%;
		display: inline-flex;
	}
	.data-highlight {
		color: $primaryColor;
		float: right;
	}
	.input-required {
		color: red;
	}
}

.react-autosuggest__container {
	position: relative;
	width: 100%;
}

.react-autosuggest__input {
	width: 100%;
	height: 35px;
	padding: 10px 10px;
	border: 1px solid #e3e8ec;
}

.react-autosuggest__input--focused {
	outline: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	width: 100%;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	border: 1px solid #e3e8ec;
	background-color: #fff;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #ddd;
}

.highlight {
	color: #e91e63;
}

.margin-right {
	margin-right: 10px;
}

.margin-left {
	margin-right: 10px;
	margin-left: 20px;
}