@import "../../scss/variables";
.customBootstrapTable {
  .card-header {
    border-bottom: 1px solid darkgrey;
  }
  table{
    position: relative;
  }
  th {
    background-color: $tableHeaderBgColor;
    color: white;
    font-weight: normal;
    padding: 5px;
    position: sticky;
    top: 0;
    height: 30px;
    border: 1px solid #dedede;
    vertical-align: initial !important;
    text-align: center !important;
    .filter {
      position: absolute;
      bottom: 0px;
      padding: 0px 10px;
      height: 20px;
      border: none;
    }
    span{
      color: #d3d2d2  !important;
    }
  }
  td {
    border: 1px solid #dedede;
    padding: 9px 10px;
  }
  tr:nth-of-type(odd) {
    background-color: #f9f9f9 !important;
  }
  .scroll{
    max-height:85vh;
    display: flex;
    flex-direction: column;
  }
  .fixed-footer{
    margin-top: 15px;
  }
  .loading{
    text-align: center;
    padding-top: 20px;
    img{
      height: 80px;
    }
  }
}

@media screen and (max-width:992px)
{
  .grid-filter.card{
    #filterTogle{
      .row{
        display:block;
      }
      .DateRangePickerInput{
        .DateRangePickerInput_arrow, .DateInput,.DateRangePickerInput_clearDates{
          display: block;
        }
        .DateRangePickerInput_arrow{
          padding:0 20px;
        }
      }
    }
  }
  .customBootstrapTable{
    .scroll{
      overflow: -webkit-paged-x;
    }
  }
}