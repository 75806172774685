@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?2cqht7');
  src:  url('fonts/icomoon.eot?2cqht7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?2cqht7') format('truetype'),
    url('fonts/icomoon.woff?2cqht7') format('woff'),
    url('fonts/icomoon.svg?2cqht7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-not-allow:before {
  content: "\e902";
}
.icon-delivery-truck:before {
  content: "\e903";
}
.icon-TALogo:before {
  content: "\e900";
}
.icon-home:before {
  content: "\e901";
}
.icon-office:before {
  content: "\e904";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-library:before {
  content: "\e921";
}
.icon-profile:before {
  content: "\e923";
}
.icon-price-tags:before {
  content: "\e936";
}
.icon-barcode:before {
  content: "\e937";
}
.icon-qrcode:before {
  content: "\e938";
}
.icon-ticket:before {
  content: "\e939";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-phone:before {
  content: "\e942";
}
.icon-location:before {
  content: "\e947";
}
.icon-location2:before {
  content: "\e948";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-happy2:before {
  content: "\e9e0";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-sad2:before {
  content: "\e9e6";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-youtube2:before {
  content: "\ea9e";
}
