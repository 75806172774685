.headerDiv {
  .menuDiv {
    margin-top: 4px;
    .mmenu {
      margin-left: 20px;
      margin-right: 40px;
    }
    .menu {
      cursor: pointer;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 12px;
      border-right: 1px solid #90949c;
      i {
        margin-right: 5px;
        color: #3c414e;
      }
      a {
        color: #3c414e;
        font-size: 12px;
      }
      &:hover {
        text-decoration: underline;
      }
      &:first-child {
        border-right: 0px solid #90949c;
      }
      &:last-child {
        border-right: 0px solid #90949c;
      }
    }
  }
  .langDiv {
    padding-right: 20px !important;
    span {
      cursor: pointer;
      padding-right: 10px;
      padding-left: 10px;
      border-right: 1px solid #90949c;
      &:hover {
        text-decoration: underline;
      }
      &:last-child {
        border-right: 0px solid #90949c;
      }
    }
    .active {
      color: rebeccapurple;
    }
  }
  .item {
    display: inline;
  }  
}

@media (max-width: 992px) {
  .app-header.navbar {
    .navbar-toggler {
      float: left;
      display: none;
    }

    .logo-header-minimized {
      float: left;
      //width: 7%;
      display: none;
    }

    .headerDiv {
      margin-top: 13px;
      background-color: #ffffff;
      border: 1px solid #e3e8ec;
      padding-top: 20px;
      width: 80%;
      display: none;
      height: 100vh;
      .langDiv {
        text-align: right;
      }
      .item {
        display: block;
        height: 40px;
        padding: 10px;
        margin-left: 15px;
        border-bottom: 1px solid #e3e8ec;
      }
    }
  }
  .mobileSubMenu {
    display: block;
    padding-right: 18px;
    width: 7%;
  }
  .mobileMenu {
    display: block;
    padding-left: 18px;
    width: 7%;
  }
  .itemMobileName {
    display: none;
  }
}

@media (max-width: 600px) {
  .itemMobileName {
    display: block;
  }
}
